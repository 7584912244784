// src/pages/About.js

import React from 'react';

function About() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">About Modifai</h1>
      <p className="text-lg text-center max-w-2xl">
        At ModifAI, we are on a mission to democratize the home improvement design and renovation experience through the power of generative AI and computer vision technology.  Our goal is to unleash every homeowner’s creative side while making home improvement easier, collaborative and more fun.  

      </p>
    </div>
  );
}

export default About;
