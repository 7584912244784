// src/components/DesignCard.jsx

import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  doc,
  deleteDoc,
  addDoc,
  collection,
  updateDoc,
  increment,
} from 'firebase/firestore';
import { db } from '../firebaseClient';
import {
  MdDownload,
  MdShare,
  MdDelete,
  MdAdd,
  MdFullscreen,
  MdFavorite,
  MdComment,
} from 'react-icons/md';
import FullScreenImage from './FullScreenImage';
import { Link } from 'react-router-dom';

const DesignCard = ({ design, onDelete, isFeed }) => {
  const { user } = useAuth();
  const [fullScreenData, setFullScreenData] = useState(null);
  const [likes, setLikes] = useState(design.likes || 0);

  const handleDelete = async () => {
    if (isFeed) {
      console.log('Delete from community feed not implemented');
      return;
    }
    await deleteDoc(doc(db, `users/${user.uid}/designs`, design.id));
    onDelete(design.id);
  };

  const handleDownload = async () => {
    try {
      const link = document.createElement('a');
      link.href = design.imageUrl;
      link.download = `design_${design.id}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading image:', error);
      alert('Failed to download image. Please try again later.');
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Check out this design from Modifai',
          text: 'I created this design using AI. What do you think?',
          url: design.imageUrl,
        });
      } else {
        alert('Web Share API is not supported in your browser.');
      }
    } catch (error) {
      console.error('Error sharing image:', error);
      alert('Failed to share image.');
    }
  };

  const handleShareToFeed = async () => {
    try {
      await addDoc(collection(db, 'communityFeed'), {
        imageUrl: design.imageUrl,
        originalImageUrl: design.originalImageUrl, // Include original image URL
        type: design.type,
        style: design.style,
        userId: user.uid,
        createdAt: new Date(),
        likes: 0,
        comments: [],
      });
      alert('Design shared to community feed!');
    } catch (error) {
      console.error('Error sharing to feed:', error);
      alert('Failed to share to community feed.');
    }
  };

  const handleLike = async () => {
    if (!user) {
      alert('Please sign in to like designs.');
      return;
    }
    try {
      const designRef = doc(db, 'communityFeed', design.id);
      await updateDoc(designRef, {
        likes: increment(1),
      });
      setLikes((prevLikes) => prevLikes + 1);
    } catch (error) {
      console.error('Error liking design:', error);
      alert('Failed to like design. Please try again.');
    }
  };

  const handleComment = () => {
    alert('Comment feature coming soon!');
  };

  const canTryDesign = design.type && design.style;

  const openFullScreen = () => {
    if (isFeed && design.originalImageUrl) {
      // For Community Feed, show before-and-after comparison
      setFullScreenData({
        originalImage: design.originalImageUrl,
        generatedImage: design.imageUrl,
      });
    } else {
      // For other cases, show single image
      setFullScreenData({ src: design.imageUrl });
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="relative group">
        <img
          src={design.imageUrl}
          alt="Design"
          className="w-full h-64 object-cover cursor-pointer"
          onClick={openFullScreen}
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
          <div className="flex space-x-4">
            <button
              onClick={handleDownload}
              className="text-white hover:text-gray-300"
              title="Download"
            >
              <MdDownload size={24} />
            </button>
            <button
              onClick={handleShare}
              className="text-white hover:text-gray-300"
              title="Share"
            >
              <MdShare size={24} />
            </button>
            {!isFeed && (
              <button
                onClick={handleShareToFeed}
                className="text-white hover:text-gray-300"
                title="Share to Community Feed"
              >
                <MdAdd size={24} />
              </button>
            )}
            <button
              onClick={openFullScreen}
              className="text-white hover:text-gray-300"
              title="Full Screen"
            >
              <MdFullscreen size={24} />
            </button>
            {!isFeed && (
              <button
                onClick={handleDelete}
                className="text-white hover:text-gray-300"
                title="Delete"
              >
                <MdDelete size={24} />
              </button>
            )}
          </div>
        </div>
      </div>
      {isFeed && (
        <div className="p-4">
          <div className="flex justify-between items-center">
            <button
              onClick={handleLike}
              className="flex items-center text-gray-600 hover:text-red-500"
            >
              <MdFavorite className="mr-1" /> {likes}
            </button>
            {canTryDesign && (
              <Link
                to={`/try-design/${design.id}`}
                className="text-blue-500 hover:text-blue-700 text-sm font-medium"
              >
                Try this Design
              </Link>
            )}
            <button
              onClick={handleComment}
              className="flex items-center text-gray-600 hover:text-blue-500"
            >
              <MdComment className="mr-1" />{' '}
              {design.comments ? design.comments.length : 0}
            </button>
          </div>
        </div>
      )}
      {fullScreenData && (
        <FullScreenImage
          src={fullScreenData.src}
          originalImage={fullScreenData.originalImage}
          generatedImage={fullScreenData.generatedImage}
          onClose={() => setFullScreenData(null)}
        />
      )}
    </div>
  );
};

export default DesignCard;
