// src/components/GifGenerator.js

import React, { useState, useEffect } from 'react';
import gifshot from 'gifshot';
import { FaSpinner } from 'react-icons/fa';
function GifGenerator({ originalImage, newImage, onClose }) {
  const [isGeneratingGif, setIsGeneratingGif] = useState(false);
  const [generatedGif, setGeneratedGif] = useState(null);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [shareImageUrl, setShareImageUrl] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    const createGif = async () => {
      try {
        setIsGeneratingGif(true);
        setErrorMessage(null);
        const originalImg = await loadImage(originalImage);
        const generatedImg = await loadImage(newImage);
        const targetWidth = Math.min(originalImg.width, 600);
        const targetHeight = Math.floor((originalImg.height / originalImg.width) * targetWidth);
        const { canvas: resizedOriginalCanvas } = resizeImage(originalImg, targetWidth, targetHeight);
        const { canvas: resizedGeneratedCanvas } = resizeImage(generatedImg, targetWidth, targetHeight);
        const frames = createTransitionFrames(resizedOriginalCanvas, resizedGeneratedCanvas);
        gifshot.createGIF(
          {
            images: frames,
            interval: 0.15,
            gifWidth: targetWidth,
            gifHeight: targetHeight,
            numFrames: frames.length,
            frameDuration: 1,
            loop: true,
          },
          function (obj) {
            setIsGeneratingGif(false);
            if (!obj.error) {
              const image = obj.image;
              setGeneratedGif(image);
              setVideoModalOpen(true);
            } else {
              console.error('Error generating GIF:', obj.errorMsg);
              setErrorMessage('Sorry, there was an error processing your GIF. Please try again.');
            }
          }
        );
      } catch (error) {
        setIsGeneratingGif(false);
        console.error('Error creating GIF:', error);
        setErrorMessage('Sorry, there was an error processing your GIF. Please try again.');
      }
    };
    createGif();
  }, [originalImage, newImage]);
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };
  const resizeImage = (img, targetWidth, targetHeight) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
    return { canvas, ctx };
  };
  const createTransitionFrames = (originalCanvas, generatedCanvas) => {
    const totalFrames = 10; // Adjust as needed
    const frames = [];
    const canvas = document.createElement('canvas');
    canvas.width = originalCanvas.width;
    canvas.height = originalCanvas.height;
    const ctx = canvas.getContext('2d');

    const watermarkText = 'Designed with Modifai.com';
    const fontSize = Math.floor(canvas.width * 0.035);
    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
    ctx.textAlign = 'center';
    const textYPosition = canvas.height - fontSize;

    // Start with the original image
    ctx.drawImage(originalCanvas, 0, 0);
    ctx.fillText(watermarkText, canvas.width / 2, textYPosition);
    frames.push(canvas.toDataURL('image/jpeg', 0.7));

    // Forward transition: Original -> New
    for (let i = 1; i <= totalFrames; i++) {
      const progress = i / totalFrames;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(originalCanvas, 0, 0);

      ctx.save();
      ctx.beginPath();
      ctx.rect(0, 0, canvas.width * progress, canvas.height);
      ctx.closePath();
      ctx.clip();

      ctx.drawImage(generatedCanvas, 0, 0);
      ctx.restore();

      ctx.fillText(watermarkText, canvas.width / 2, textYPosition);
      frames.push(canvas.toDataURL('image/jpeg', 0.7));
    }

    // Display the new image fully
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(generatedCanvas, 0, 0);
    ctx.fillText(watermarkText, canvas.width / 2, textYPosition);
    frames.push(canvas.toDataURL('image/jpeg', 0.7));

    // Reverse transition: New -> Original
    for (let i = 1; i <= totalFrames; i++) {
      const progress = i / totalFrames;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(generatedCanvas, 0, 0);

      ctx.save();
      ctx.beginPath();
      ctx.rect(canvas.width * (1 - progress), 0, canvas.width * progress, canvas.height);
      ctx.closePath();
      ctx.clip();

      ctx.drawImage(originalCanvas, 0, 0);
      ctx.restore();

      ctx.fillText(watermarkText, canvas.width / 2, textYPosition);
      frames.push(canvas.toDataURL('image/jpeg', 0.7));
    }

    // End with the original image
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(originalCanvas, 0, 0);
    ctx.fillText(watermarkText, canvas.width / 2, textYPosition);
    frames.push(canvas.toDataURL('image/jpeg', 0.7));

    return frames;
  };

  // Function to handle downloading the generated GIF
  const handleDownloadGif = async (gifDataUrl) => {
    try {
      setIsPreparingDownload(true);
      const response = await fetch(gifDataUrl);
      const blob = await response.blob();
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = 'before_and_after.gif';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error('Error downloading GIF:', error);
      alert('An error occurred while downloading the animation. Please try again.');
    } finally {
      setIsPreparingDownload(false);
    }
  };

  // Function to handle sharing the generated GIF
  const handleShareGif = async (gifDataUrl) => {
    try {
      // Fetch the GIF
      const response = await fetch(gifDataUrl);
      const blob = await response.blob();

      // Create a File object from the Blob
      const file = new File([blob], 'before_and_after.gif', { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        try {
          await navigator.share({
            title: 'Check out this before and after animation',
            text: 'I created this animation using AI. What do you think?',
            files: [file],
          });
          console.log('GIF shared successfully');
        } catch (error) {
          console.error('Error sharing GIF:', error);
          // Fall back to opening the share modal
          setShareImageUrl(URL.createObjectURL(blob));
          setShareModalOpen(true);
        }
      } else {
        // Open the share modal for browsers that don't support sharing files
        setShareImageUrl(URL.createObjectURL(blob));
        setShareModalOpen(true);
      }
    } catch (error) {
      console.error('Error processing GIF for share:', error);
      alert('Unable to process the animation. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4 mt-6">
      {isGeneratingGif && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <FaSpinner className="animate-spin text-gray-500 text-5xl mb-4" />
            <p className="text-white text-lg">Processing your GIF</p>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center z-50">
          <div className="bg-yellow-500 text-black p-6 rounded-lg">
            <p className="text-lg">{errorMessage}</p>
            <button
              onClick={() => setErrorMessage(null)}
              className="mt-4 bg-white text-yellow-700 px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Display the generated GIF in fullscreen modal */}
      {videoModalOpen && generatedGif && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <button
              className="absolute top-2 right-2 text-white text-3xl font-bold"
              onClick={() => setVideoModalOpen(false)}
              aria-label="Close GIF Preview"
            >
              &times;
            </button>
            <img
              src={generatedGif}
              alt="Generated GIF"
              className="max-w-full max-h-screen"
            />
            <div className="flex justify-center mt-4 space-x-4">
              <button
                className="btn bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                onClick={() => handleDownloadGif(generatedGif)}
                disabled={isPreparingDownload}
              >
                {isPreparingDownload ? 'Preparing Download...' : 'Download'}
              </button>
              <button
                className="btn bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                onClick={() => handleShareGif(generatedGif)}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Share Modal for unsupported browsers */}
      {shareModalOpen && shareImageUrl && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative bg-gray-800 p-6 rounded-lg">
            <button
              className="absolute top-2 right-2 text-white text-3xl font-bold"
              onClick={() => setShareModalOpen(false)}
              aria-label="Close Share Modal"
            >
              &times;
            </button>
            <img
              src={shareImageUrl}
              alt="Share GIF"
              className="max-w-full max-h-80 object-contain mb-4"
            />
            <div className="flex justify-center space-x-4">
              <a
                href={shareImageUrl}
                download="before_and_after.gif"
                className="btn bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
              >
                Download
              </a>
              <button
                className="btn bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                onClick={() => {
                  navigator.clipboard.writeText(shareImageUrl);
                  alert('GIF URL copied to clipboard!');
                }}
              >
                Copy URL
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GifGenerator;

