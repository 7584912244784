// client/src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Upload from './pages/Upload';
import Results from './pages/Results';
import About from './pages/About';
import Header from './components/Header';
import { AuthProvider } from './contexts/AuthContext';
import DoorReplacement from './pages/DoorReplacement';
import MyDesigns from './pages/MyDesigns';
import TryDesignUpload from './components/TryDesignUpload';
import CommunityFeed from './pages/CommunityFeed';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/results" element={<Results />} />
          <Route path="/about" element={<About />} />
          <Route path="/door-replacement" element={<DoorReplacement />} />
          <Route path="/my-designs" element={<MyDesigns />} />
          <Route path="/community" element={<CommunityFeed />} />
          <Route path="/try-design/:designId" element={<TryDesignUpload />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;