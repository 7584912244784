import React from 'react';
import CommunityFeedComponent from '../components/CommunityFeedComponent';

const CommunityFeed = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-white">Community Designs</h1>
      <CommunityFeedComponent showTabs={true} />
    </div>
  );
};

export default CommunityFeed;