import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FullScreenImage from './FullScreenImage';
import { MdDownload, MdShare, MdMovie, MdPushPin, MdAdd } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import GifGenerator from './GifGenerator';

function ImageGrid({ originalImage, generatedImages, generatedLabels, loading, onPin, onShare }) {
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [selectedForGif, setSelectedForGif] = useState(null);
  const [showMyDesignsLink, setShowMyDesignsLink] = useState(false);

  const openFullScreen = (src) => {
    setFullScreenImage(src);
  };

  const closeFullScreen = () => {
    setFullScreenImage(null);
  };

  const handleDownloadClick = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = imageUrl.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
      alert('Failed to download image.');
    }
  };

  const handleShareClick = async (imageUrl) => {
    try {
      const img = await loadImage(imageUrl);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      const watermarkText = 'Designed with Modifai.com';
      const fontSize = Math.floor(canvas.width * 0.035);
      ctx.font = `${fontSize}px Arial`;
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.textAlign = 'center';
      const textYPosition = canvas.height - fontSize;
      ctx.fillText(watermarkText, canvas.width / 2, textYPosition);

      const watermarkedBlob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 0.8);
      });

      const file = new File([watermarkedBlob], 'shared_image.jpg', { type: 'image/jpeg' });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        try {
          await navigator.share({
            title: 'Check out this design from Modifai',
            text: 'I created this design using AI. What do you think?',
            files: [file],
          });
        } catch (error) {
          console.error('Error sharing image:', error);
          alert('Failed to share image.');
        }
      } else {
        alert('Share not supported on this browser.');
      }
    } catch (error) {
      console.error('Error sharing image:', error);
      alert('Failed to share image.');
    }
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  };

  const handleGifClick = (imageUrl) => {
    setSelectedForGif(imageUrl);
  };

  const handlePinClick = async (src) => {
    await onPin(src);
    setShowMyDesignsLink(true);
    setTimeout(() => setShowMyDesignsLink(false), 5000);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full sm:w-2/3 mx-auto">
      <div className="relative">
        <img
          src={originalImage}
          alt="Original"
          onClick={() => openFullScreen(originalImage)}
          className="w-full h-auto object-contain cursor-pointer"
        />
        <span className="absolute top-2 left-2 bg-black text-white px-2 py-1 text-sm">
          Original
        </span>
      </div>

      {generatedImages.map((src, index) => (
        <div key={index} className="relative">
          {loading[index] ? (
            <div className="w-full h-full flex flex-col items-center justify-center bg-transparent">
              <FaSpinner className="animate-spin text-gray-500 text-4xl sm:text-5xl mb-4" />
              <p className="text-center text-gray-300 text-sm sm:text-base">
                Processing your image. This may take up to 2 minutes the first time. Thanks for your patience.
              </p>
            </div>
          ) : (
            src && (
              <img
                src={src}
                alt={`Generated ${index + 1}`}
                onClick={() => openFullScreen(src)}
                className="w-full h-auto object-contain cursor-pointer"
              />
            )
          )}
          {src && !loading[index] && (
            <>
              <span className="absolute top-2 left-2 bg-blue-500 text-white px-2 py-1 text-sm">
                {generatedLabels && generatedLabels[index] ? generatedLabels[index] : `Generated ${index + 1}`}
              </span>
              <div className="absolute bottom-4 left-0 w-full flex justify-between px-4">
                <button
                  onClick={() => handleDownloadClick(src)}
                  className="text-white hover:text-gray-300 relative group"
                  aria-label="Download Image"
                >
                  <MdDownload className="text-3xl sm:text-5xl" />
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Download
                  </span>
                </button>
                <button
                  onClick={() => handleShareClick(src)}
                  className="text-white hover:text-gray-300 relative group"
                  aria-label="Share Image"
                >
                  <MdShare className="text-3xl sm:text-5xl" />
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Share
                  </span>
                </button>
                <button
                  onClick={() => handleGifClick(src)}
                  className="text-white hover:text-gray-300 relative group"
                  aria-label="Create GIF"
                >
                  <MdMovie className="text-3xl sm:text-5xl" />
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Create GIF
                  </span>
                </button>
                <button
                  onClick={() => handlePinClick(src)}
                  className="text-white hover:text-gray-300 relative group"
                  aria-label="Pin Design"
                >
                  <MdPushPin className="text-3xl sm:text-5xl" />
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Pin Design
                  </span>
                </button>
                <button
                  onClick={() => onShare(src)}
                  className="text-white hover:text-gray-300 relative group"
                  aria-label="Share to Community Feed"
                >
                  <MdAdd className="text-3xl sm:text-5xl" />
                  <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    Share to Community Feed
                  </span>
                </button>
              </div>
            </>
          )}
        </div>
      ))}

      {showMyDesignsLink && (
        <div className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded shadow">
          Design pinned! <Link to="/my-designs" className="underline">View My Designs</Link>
        </div>
      )}

      {selectedForGif && (
        <GifGenerator
          originalImage={originalImage}
          newImage={selectedForGif}
        />
      )}

      {fullScreenImage && (
        <FullScreenImage src={fullScreenImage} onClose={closeFullScreen} />
      )}
    </div>
  );
}

export default ImageGrid;