import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebaseClient';
import { collection, query, where, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import DesignCard from './DesignCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const CommunityFeedComponent = ({ showTabs = true }) => {
  const [designs, setDesigns] = useState([]);
  const [activeTab, setActiveTab] = useState('exterior');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const DESIGNS_PER_PAGE = 24;

  const fetchDesigns = async (isInitial = false) => {
    if (isInitial) {
      setLoading(true);
      setError(null);
    }

    try {
      const designsRef = collection(db, 'communityFeed');
      let q = query(
        designsRef,
        where('type', '==', activeTab),
        orderBy('createdAt', 'desc'),
        limit(DESIGNS_PER_PAGE)
      );

      if (!isInitial && lastVisible) {
        q = query(
          designsRef,
          where('type', '==', activeTab),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisible),
          limit(DESIGNS_PER_PAGE)
        );
      }

      const designsSnapshot = await getDocs(q);
      const designsList = designsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt ? doc.data().createdAt.toDate() : new Date()
      }));

      if (isInitial) {
        setDesigns(designsList);
      } else {
        setDesigns(prev => [...prev, ...designsList]);
      }

      setLastVisible(designsSnapshot.docs[designsSnapshot.docs.length - 1]);
      setHasMore(designsSnapshot.docs.length === DESIGNS_PER_PAGE);

    } catch (err) {
      console.error("Error fetching designs:", err);
      if (err.code === 'failed-precondition' || err.code === 'resource-exhausted') {
        setError("We're setting up some things. Please try again in a few minutes.");
      } else {
        setError("Failed to load designs. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDesigns(true);
  }, [activeTab]);

  const handleDeleteDesign = (designId) => {
    setDesigns(designs.filter(design => design.id !== designId));
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      fetchDesigns(false);
    }
  };

  return (
    <div>
      {showTabs && (
        <div className="flex justify-center mb-6">
          <button
            className={`px-4 py-2 mr-2 rounded ${
              activeTab === 'exterior' 
                ? 'bg-blue-500 text-white' 
                : 'bg-gray-200 text-gray-800'  // Changed from text-white
            }`}
            onClick={() => setActiveTab('exterior')}
          >
            Exterior
          </button>
          <button
            className={`px-4 py-2 rounded ${
              activeTab === 'interior' 
                ? 'bg-blue-500 text-white' 
                : 'bg-gray-200 text-gray-800'  // Changed from text-white
            }`}
            onClick={() => setActiveTab('interior')}
          >
            Interior
          </button>
        </div>
      )}

      {error && <p className="text-center text-red-500">{error}</p>}

      <InfiniteScroll
        dataLength={designs.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<h4 className="text-center py-4">Loading more designs...</h4>}
        endMessage={
          <p className="text-center py-4 text-gray-500">
            {designs.length === 0 ? "No designs found for this category." : "You've seen all designs in this category!"}
          </p>
        }
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {designs.map(design => (
            <DesignCard 
              key={design.id} 
              design={design} 
              isFeed={true}
              onDelete={handleDeleteDesign} 
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default CommunityFeedComponent;