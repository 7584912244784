// src/utils/utils.js

import _ from "lodash";

/**
 * Processes the detection API response to extract door information.
 *
 * @param {Object} uploadData - The JSON response from the Detection API.
 * @param {string} page - The type of page ('garage' or 'frontdoor').
 * @returns {Array} - A list of door objects with coordinates and labels.
 */
export const getUploadedHouseImageData = (uploadData, page) => {
  if (!uploadData || typeof uploadData !== 'object') {
    console.error("Invalid upload data provided.");
    return [];
  }

  // Access the 'response' key from the detection API response
  const responseNested = _.get(uploadData, "response", []);

  if (!Array.isArray(responseNested)) {
    console.error("The 'response' field is not an array.");
    return [];
  }

  // Flatten the nested array to get a flat list of door objects
  const doorList = _.flatten(responseNested);

  if (!doorList.length) {
    console.warn("No doors detected in the response.");
    return [];
  }

  // Initialize an empty array to hold filtered doors
  let filteredDoors = [];

  // Iterate over each door object to filter based on labels
  doorList.forEach((door, index) => {
    if (!door || typeof door !== 'object') {
      console.warn(`Door at index ${index} is not a valid object.`);
      return;
    }

    const labels = door.labels;
    const rois = door.rois;

    if (!Array.isArray(labels)) {
      console.warn(`'labels' is not an array for door at index ${index}.`);
      return;
    }

    if (!Array.isArray(rois) || rois.length < 4) {
      console.warn(`'rois' is invalid or insufficient for door at index ${index}.`);
      return;
    }

    // Determine if the door matches the specified page type
    let isValid = false;

    if (page === 'garage') {
      isValid = labels.some(label => ["garage:one-car", "garage:two-car"].includes(label));
    } else if (page === 'frontdoor') {
      isValid = labels.some(label => ["front door:single", "front door:double"].includes(label));
    } else {
      console.warn(`Unknown page type: ${page}`);
      return;
    }

    if (isValid) {
      // Extract coordinates from rois
      const [topLeft, topRight, bottomRight, bottomLeft] = rois;

      // Validate that each corner has at least two numerical values
      if (
        !Array.isArray(topLeft) || topLeft.length < 2 ||
        !Array.isArray(topRight) || topRight.length < 2 ||
        !Array.isArray(bottomRight) || bottomRight.length < 2 ||
        !Array.isArray(bottomLeft) || bottomLeft.length < 2
      ) {
        console.warn(`Invalid coordinates in 'rois' for door at index ${index}.`);
        return;
      }

      filteredDoors.push({
        topLeft: { x: topLeft[0], y: topLeft[1] },
        topRight: { x: topRight[0], y: topRight[1] },
        bottomRight: { x: bottomRight[0], y: bottomRight[1] },
        bottomLeft: { x: bottomLeft[0], y: bottomLeft[1] },
        label: labels.find(label => ["garage:one-car", "garage:two-car", "front door:single", "front door:double"].includes(label)) || labels[0],
      });
    }
  });

  if (!filteredDoors.length) {
    console.warn(`No doors matching the criteria for ${page} detected.`);
  }

  return filteredDoors;
};
