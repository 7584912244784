// src/pages/Home.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommunityFeedComponent from '../components/CommunityFeedComponent';

function Home() {
  const navigate = useNavigate();

  const handleNavigate = (type) => {
    navigate('/upload', { state: { type } });
  };

  const handleDoorReplacement = () => {
    navigate('/door-replacement');
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div 
        className="flex-grow bg-cover bg-center flex flex-col items-center justify-start sm:justify-center py-8 sm:py-40 h-[75vh] sm:h-auto"
        style={{ backgroundImage: `url('/out-3-36.png')` }}
      >
        <main className="text-center text-white flex flex-col items-center space-y-4 sm:space-y-6 mt-20 sm:mt-0">
          <h2 className="text-4xl sm:text-6xl font-extrabold animate-fadeIn">
            Modifai Your Home
          </h2>
          <h1 className="text-2xl sm:text-3xl font-bold animate-fadeIn">            
          </h1>
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 w-full sm:w-auto px-4 sm:px-0">
            <button 
              className="btn bg-green-600 hover:bg-green-700 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg w-40 sm:w-auto mx-auto sm:mx-0"
              onClick={() => handleNavigate('exterior')}
            >
              Update Exterior
            </button>
            <button 
              className="btn bg-blue-600 hover:bg-blue-700 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg w-40 sm:w-auto mx-auto sm:mx-0"
              onClick={() => handleNavigate('interior')}
            >
              Update Interior
            </button>
            <button 
              className="btn bg-purple-600 hover:bg-purple-700 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg w-40 sm:w-auto mx-auto sm:mx-0"
              onClick={handleDoorReplacement}
            >
              Replace Doors
            </button>
          </div>
          {/* Updated handwritten callout with hand-drawn arrow */}
          <div className="relative w-full mt-12">
            <div 
              className="text-center"
              style={{ fontFamily: 'Caveat, cursive' }}
            >
                <p className="text-4xl sm:text-5xl text-white font-bold sm:font-normal !important">
                Try on Designs from our Community
              </p>
              <svg 
                className="w-16 h-16 mx-auto mt-4" 
                viewBox="0 0 100 100"
              >
                <path
                  d="M50 5 C50 5, 50 75, 50 75 C50 75, 35 60, 35 60 C35 60, 50 95, 50 95 C50 95, 65 60, 65 60 C65 60, 50 75, 50 75"
                  fill="none"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{
                    strokeDasharray: 300,
                    strokeDashoffset: 300,
                    animation: "drawing 2s ease forwards"
                  }}
                />
              </svg>
            </div>
          </div>
        </main>
      </div>

      <div className="w-full bg-gray-800 py-12 sm:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-white">Community Designs</h2>
          <div className="text-gray-200">
            <CommunityFeedComponent limitCount={16} showTabs={true} />
          </div>
          <div className="text-center mt-8">
            <button 
              className="btn bg-blue-600 hover:bg-blue-700 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg"
              onClick={() => navigate('/community')}
            >
              View More Designs
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;