import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SignInPrompt from './SignInPrompt';
import { FaBars } from 'react-icons/fa';

function Header() {
  const { user, signOut } = useAuth();
  const [isSignInPromptOpen, setIsSignInPromptOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-[#2d3748] p-4">
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/">
          <img 
            src="/transparent-logo.png"
            alt="Modifai Logo" 
            className="h-16 w-auto"
          />
        </Link>

        <button
          className="text-white lg:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <FaBars className="h-6 w-6" />
        </button>

        <nav className={`flex-col lg:flex-row lg:flex space-x-0 lg:space-x-6 items-center text-white ${isMenuOpen ? 'flex' : 'hidden'} lg:flex`}>
          <Link 
            to="/upload" 
            state={{ type: 'interior' }} 
            className="hover:text-gray-300 mt-2 lg:mt-0"
          >
            Interior
          </Link>
          <Link 
            to="/upload" 
            state={{ type: 'exterior' }} 
            className="hover:text-gray-300 mt-2 lg:mt-0"
          >
            Exterior
          </Link>
          <Link 
            to="/door-replacement" 
            className="hover:text-gray-300 mt-2 lg:mt-0"
          >
            Doors
          </Link>
          <Link 
            to="/community" 
            className="hover:text-gray-300 mt-2 lg:mt-0"
          >
            Community
          </Link>
          {user && (
            <Link 
              to="/my-designs" 
              className="hover:text-gray-300 mt-2 lg:mt-0"
            >
              My Designs
            </Link>
          )}
          <Link 
            to="/about" 
            className="hover:text-gray-300 mt-2 lg:mt-0"
          >
            About
          </Link>

          {user ? (
            <>
              <span className="text-white mt-2 lg:mt-0">Hello, {user.email}</span>
              <button
                onClick={signOut}
                className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded mt-2 lg:mt-0"
              >
                Sign Out
              </button>
            </>
          ) : (
            <button
              onClick={() => setIsSignInPromptOpen(true)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded mt-2 lg:mt-0"
            >
              Sign In
            </button>
          )}
        </nav>
      </div>

      <SignInPrompt
        isOpen={isSignInPromptOpen}
        onClose={() => setIsSignInPromptOpen(false)}
      />
    </header>
  );
}

export default Header;