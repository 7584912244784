import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebaseClient';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import DesignCard from '../components/DesignCard';
import FullScreenImage from '../components/FullScreenImage';

const MyDesigns = () => {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchDesigns = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const designsRef = collection(db, `users/${user.uid}/designs`);
        const designsQuery = query(designsRef, orderBy('createdAt', 'desc'));
        const designsSnapshot = await getDocs(designsQuery);

        const designsList = designsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate()
        }));

        setDesigns(designsList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching designs:", err);
        setError("Failed to load designs. Please try again later.");
        setLoading(false);
      }
    };

    fetchDesigns();
  }, [user]);

  const handleDeleteDesign = (designId) => {
    setDesigns(designs.filter(design => design.id !== designId));
  };

  const openFullScreen = (src) => {
    setFullScreenImage(src);
  };

  const closeFullScreen = () => {
    setFullScreenImage(null);
  };

  if (loading) {
    return <div className="text-center mt-8">Loading designs...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-white">My Designs</h1>
      {designs.length === 0 ? (
        <p className="text-center text-gray-500">You haven't pinned any designs yet.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {designs.map(design => (
            <DesignCard 
              key={design.id} 
              design={design} 
              onDelete={handleDeleteDesign}
              onOpenFullScreen={openFullScreen}
            />
          ))}
        </div>
      )}
      {fullScreenImage && (
        <FullScreenImage src={fullScreenImage} onClose={closeFullScreen} />
      )}
    </div>
  );
};

export default MyDesigns;