// client/src/contexts/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, googleProvider, db } from '../firebaseClient';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendEmailVerification,
  signInWithPopup,
  onAuthStateChanged,
} from 'firebase/auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      if (currentUser) {
        setEmailConfirmed(currentUser.emailVerified);
        try {
          const userDocRef = doc(db, 'user_data', currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const existingUserData = userDoc.data();
            if (!existingUserData.signUpDate) {
              const updatedUserData = {
                ...existingUserData,
                signUpDate: new Date().toISOString()
              };
              await updateDoc(userDocRef, updatedUserData);
              setUserData(updatedUserData);
            } else {
              setUserData(existingUserData);
            }
          } else {
            const newUserData = {
              email: currentUser.email,
              customerID: currentUser.uid,
              generation_count: 0,
              isSubscribed: false,
              dateSubscribed: null,
              signUpDate: new Date().toISOString()
            };
            await setDoc(userDocRef, newUserData);
            setUserData(newUserData);
          }
        } catch (error) {
          console.error("Error fetching/updating user data:", error);
        }
      } else {
        setEmailConfirmed(false);
        setUserData(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDocRef = doc(db, 'user_data', userCredential.user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        const newUserData = {
          email: email,
          customerID: userCredential.user.uid,
          generation_count: 0,
          isSubscribed: false,
          dateSubscribed: null,
          signUpDate: new Date().toISOString()
        };
        await setDoc(userDocRef, newUserData);
        setUserData(newUserData);
      } else {
        const existingUserData = userDoc.data();
        if (!existingUserData.signUpDate) {
          const updatedUserData = {
            ...existingUserData,
            signUpDate: new Date().toISOString()
          };
          await updateDoc(userDocRef, updatedUserData);
          setUserData(updatedUserData);
        } else {
          setUserData(existingUserData);
        }
      }
      return userCredential;
    } catch (error) {
      console.error("Error signing in:", error);
      throw error;
    }
  };

  const signUp = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      const signUpDate = new Date().toISOString();
      const newUserData = {
        email: email,
        customerID: userCredential.user.uid,
        generation_count: 0,
        isSubscribed: false,
        dateSubscribed: null,
        signUpDate: signUpDate
      };
      const userDocRef = doc(db, 'user_data', userCredential.user.uid);
      await setDoc(userDocRef, newUserData);
      setUserData(newUserData);
      console.log("User signed up with data:", newUserData);
      return userCredential;
    } catch (error) {
      console.error("Error signing up:", error);
      throw error;
    }
  };

  const signOutUser = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      setUserData(null);
      setEmailConfirmed(false);
    } catch (error) {
      console.error("Error signing out:", error);
      throw error;
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userDocRef = doc(db, 'user_data', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        const signUpDate = new Date().toISOString();
        const newUserData = {
          email: user.email,
          customerID: user.uid,
          generation_count: 0,
          isSubscribed: false,
          dateSubscribed: null,
          signUpDate: signUpDate
        };
        await setDoc(userDocRef, newUserData);
        setUserData(newUserData);
        console.log("User signed up with Google, data:", newUserData);
      } else {
        const existingUserData = userDoc.data();
        if (!existingUserData.signUpDate) {
          const updatedUserData = {
            ...existingUserData,
            signUpDate: new Date().toISOString()
          };
          await updateDoc(userDocRef, updatedUserData);
          setUserData(updatedUserData);
        } else {
          setUserData(existingUserData);
        }
      }
      return result;
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error;
    }
  };

  const incrementGenerationCount = async () => {
    if (!user) return;
    try {
      const userDocRef = doc(db, 'user_data', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const currentCount = userDoc.data().generation_count || 0;
        const updatedUserData = {
          ...userDoc.data(),
          generation_count: currentCount + 1
        };
        await updateDoc(userDocRef, updatedUserData);
        setUserData(updatedUserData);
        return currentCount + 1;
      } else {
        const newUserData = {
          email: user.email,
          customerID: user.uid,
          generation_count: 1,
          isSubscribed: false,
          dateSubscribed: null,
          signUpDate: new Date().toISOString()
        };
        await setDoc(userDocRef, newUserData);
        setUserData(newUserData);
        return 1;
      }
    } catch (error) {
      console.error("Error incrementing generation count:", error);
      throw error;
    }
  };

  const updateSubscriptionStatus = async (isSubscribed) => {
    if (!user) return;
    try {
      const userDocRef = doc(db, 'user_data', user.uid);
      const updatedUserData = {
        ...userData,
        isSubscribed,
        dateSubscribed: isSubscribed ? new Date().toISOString() : null
      };
      await updateDoc(userDocRef, updatedUserData);
      setUserData(updatedUserData);
    } catch (error) {
      console.error("Error updating subscription status:", error);
      throw error;
    }
  };

  const value = {
    user,
    emailConfirmed,
    userData,
    signIn,
    signUp,
    signOut: signOutUser,
    signInWithGoogle,
    incrementGenerationCount,
    updateSubscriptionStatus,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}