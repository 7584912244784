// client/src/components/SubscribePrompt.js

import React from 'react';

function SubscribePrompt({ isOpen, onClose }) {
  if (!isOpen) return null;

  const handleSubscribe = () => {
    // Redirect to your subscription page or integrate payment here
    window.location.href = '/subscribe';
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-100 rounded-lg shadow-lg p-6 w-11/12 sm:w-1/3 relative">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Subscribe Now!</h2>
        <p className="text-gray-700 mb-6">
          You've reached your generation limit. Subscribe now to continue generating unlimited designs.
        </p>
        <button
          onClick={handleSubscribe}
          className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4"
        >
          Subscribe
        </button>
        <button
          onClick={onClose}
          className="w-full bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default SubscribePrompt;
