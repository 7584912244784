import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SignInPrompt from '../components/SignInPrompt';
import SubscribePrompt from '../components/SubscribePrompt';
import DoorReplacementGrid from '../components/DoorReplacementGrid';
import { useAuth } from '../contexts/AuthContext';
import Select from 'react-select';
import { getUploadedHouseImageData } from '../utils/utils';
import Modal from '../components/Modal';

function DoorReplacement() {
  const { user, emailConfirmed, userData, incrementGenerationCount } = useAuth();

  const [selectedFile, setSelectedFile] = useState(null);
  const [detectedDoors, setDetectedDoors] = useState([]);
  const [houseImageURL, setHouseImageURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [doorType, setDoorType] = useState('garage');
  const [isFirstUpload, setIsFirstUpload] = useState(true);
  const [isSignInPromptOpen, setIsSignInPromptOpen] = useState(false);
  const [isSubscribePromptOpen, setIsSubscribePromptOpen] = useState(false);
  const [isGuidanceModalOpen, setIsGuidanceModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (user) {
      if (!emailConfirmed) {
        alert('Please confirm your email before using the app.');
        setIsSignInPromptOpen(true);
        return;
      }
    }
  }, [user, emailConfirmed]);

  const handleFileButtonClick = () => {
    setIsGuidanceModalOpen(true);
  };

  const handleGuidanceModalClose = () => {
    setIsGuidanceModalOpen(false);
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      setSelectedFile(file);
      setDetectedDoors([]);
      setIsFirstUpload(true);
    } else {
      alert('Please upload a valid .jpg or .png image.');
    }
  };

  const handleDoorTypeChange = (selectedOption) => {
    setDoorType(selectedOption.value);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please choose a picture.');
      return;
    }

    if (!doorType) {
      alert('Please select a door type.');
      return;
    }

    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('image', selectedFile);

      const response = await axios.post('https://mlservermodifai.link/applyTrans', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (!response.data) {
        throw new Error("No data received from Detection API.");
      }

      const detectionData = response.data;

      const doors = getUploadedHouseImageData(detectionData, doorType);
      setDetectedDoors(doors);

      if (doors.length === 0) {
        alert(`No ${doorType === 'garage' ? 'garage' : 'entry'} doors detected in the image.`);
      }

      setHouseImageURL(URL.createObjectURL(selectedFile));

    } catch (error) {
      console.error('Error during door replacement:', error);
      alert('An error occurred while processing the image. Please try again.');
    }

    setIsLoading(false);
  };

  return (
    <div className="p-4 sm:p-6 w-full bg-gray-800 text-white min-h-screen flex flex-col items-center">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Replace Your Doors
      </h2>

      <div className="w-full sm:w-2/3">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:space-x-4 mb-6">
          <div className="mb-4 sm:mb-0 flex-1">
            <button
              onClick={handleFileButtonClick}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Choose File
            </button>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>

          <div className="mb-4 sm:mb-0 flex-1">
            <Select
              placeholder="Select Type"
              options={[
                { label: "Garage Door", value: "garage" },
                { label: "Entry Door", value: "frontdoor" },
              ]}
              value={{ label: doorType === 'garage' ? 'Garage Door' : 'Entry Door', value: doorType }}
              onChange={handleDoorTypeChange}
              isMulti={false}
              closeMenuOnSelect={true}
              className="text-black"
              styles={{
                control: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  color: 'black',
                  borderColor: '#CBD5E0',
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: 'black',
                }),
                option: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.isFocused ? '#F7FAFC' : 'white',
                  color: 'black',
                }),
                menu: (provided) => ({
                  ...provided,
                  backgroundColor: 'white',
                  color: 'black',
                }),
              }}
            />
          </div>

          <div className="flex-1">
            <button
              onClick={handleUpload}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Upload & Detect'}
            </button>
          </div>
        </div>
      </div>

      {detectedDoors.length > 0 && (
        <DoorReplacementGrid 
          houseImage={houseImageURL} 
          detectedDoors={detectedDoors} 
          pageType={doorType}
          isFirstUpload={isFirstUpload}
          setIsFirstUpload={setIsFirstUpload}
          setDetectedDoors={setDetectedDoors}
        />
      )}

      <SignInPrompt
        isOpen={isSignInPromptOpen}
        onClose={() => setIsSignInPromptOpen(false)}
      />

      <SubscribePrompt
        isOpen={isSubscribePromptOpen}
        onClose={() => setIsSubscribePromptOpen(false)}
      />

      <Modal isOpen={isGuidanceModalOpen} onClose={handleGuidanceModalClose}>
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">Image Guidance</h3>
          <img src="/doorsprompt.PNG" alt="Door Image Guidance" className="w-full mb-2" />
          <p className="mb-2">Please ensure your image meets these guidelines for best results.</p>
          <div className="text-center">
            <button
              onClick={handleGuidanceModalClose}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Got it, let me choose a file
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default DoorReplacement;