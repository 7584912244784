// src/components/TryDesignUpload.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebaseClient';
import { useAuth } from '../contexts/AuthContext';
import SignInPrompt from './SignInPrompt';
import SubscribePrompt from './SubscribePrompt';

const TryDesignUpload = () => {
  const { designId } = useParams();
  const navigate = useNavigate();
  const [design, setDesign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);

  const { user, emailConfirmed, incrementGenerationCount } = useAuth();
  const [isSignInPromptOpen, setIsSignInPromptOpen] = useState(false);
  const [isSubscribePromptOpen, setIsSubscribePromptOpen] = useState(false);
  const [generateCount, setGenerateCount] = useState(0);

  const UNAUTH_GENERATE_LIMIT = 2;
  const AUTH_GENERATE_LIMIT = 1000;

  useEffect(() => {
    const fetchDesign = async () => {
      try {
        const designDoc = await getDoc(doc(db, 'communityFeed', designId));
        if (designDoc.exists()) {
          setDesign(designDoc.data());
        } else {
          setError('Design not found');
        }
      } catch (err) {
        console.error('Error fetching design:', err);
        setError('Failed to load design. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchDesign();
    initializeGenerateCount();
  }, [designId, user]);

  const initializeGenerateCount = async () => {
    const count = await getGenerateCount();
    setGenerateCount(count);
  };

  const getGenerateCount = async () => {
    if (user) {
      const userDocRef = doc(db, 'user_data', user.uid);
      const userDoc = await getDoc(userDocRef);
      return userDoc.exists() ? (userDoc.data().generation_count || 0) : 0;
    } else {
      return parseInt(localStorage.getItem('generateCount') || '0', 10);
    }
  };

  const updateGenerateCount = async () => {
    if (user) {
      const userDocRef = doc(db, 'user_data', user.uid);
      await updateDoc(userDocRef, { generation_count: increment(1) });
      incrementGenerationCount();
    } else {
      const currentCount = await getGenerateCount();
      localStorage.setItem('generateCount', (currentCount + 1).toString());
    }
    setGenerateCount(prev => prev + 1);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageDataUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGenerate = async () => {
    if (!imageDataUrl) {
      alert('Please upload an image.');
      return;
    }

    if (user) {
      if (!emailConfirmed) {
        alert('Please confirm your email before using the app.');
        setIsSignInPromptOpen(true);
        return;
      }

      if (generateCount >= AUTH_GENERATE_LIMIT) {
        setIsSubscribePromptOpen(true);
        return;
      }
    } else {
      if (generateCount >= UNAUTH_GENERATE_LIMIT) {
        setIsSignInPromptOpen(true);
        return;
      }
    }

    await updateGenerateCount();

    navigate('/results', {
      state: {
        imageDataUrl,
        style: design.style,
        type: design.type
      }
    });
  };

  if (loading) return <div className="text-center text-white mt-8">Loading...</div>;
    if (error) return <div className="text-center text-red-500 mt-8">{error}</div>;
    return (
      <div className="container mx-auto px-4 py-8 flex flex-col items-center">
        <h1 className="text-4xl font-bold mb-6 text-white text-center">
          Try This Design
        </h1>

        <p className="mb-8 text-white text-center text-lg">
          Upload an image to try the {design.style} style for your {design.type} design
        </p>

        <div className="w-full max-w-md mb-8">
          <label className="flex flex-col items-center px-4 py-6 bg-gray-800 text-white rounded-lg shadow-lg tracking-wide border border-blue-500 cursor-pointer hover:bg-gray-700 hover:border-blue-400 transition-colors duration-200">
            <svg className="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="mt-2 text-base">Select an image</span>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
          </label>
        </div>
        {imageDataUrl && (
          <div className="flex flex-col items-center space-y-6 w-full">
            <div className="relative w-full max-w-2xl">
              <img 
                src={imageDataUrl} 
                alt="Preview" 
                className="w-full h-auto rounded-lg shadow-xl"
                style={{ maxHeight: '60vh', objectFit: 'contain' }}
              />
            </div>

            <button
              onClick={handleGenerate}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition-colors duration-200 text-lg"
            >
              Generate Design
            </button>
          </div>
        )}
        <SignInPrompt
          isOpen={isSignInPromptOpen}
          onClose={() => setIsSignInPromptOpen(false)}
        />
        <SubscribePrompt
          isOpen={isSubscribePromptOpen}
          onClose={() => setIsSubscribePromptOpen(false)}
        />
      </div>
    );
  };
  export default TryDesignUpload;